import { jwtDecode } from 'jwt-decode'
import type { IPromo } from '@/modules/cart/types/cart.d'
import type { ICustomer } from '~/modules/customer/types/customer'
import useCart from './useCart'

export default async () => {
  try {
    const baseURL = useRuntimeConfig().public.api.awsGateway

    const { accessToken } = useAuth()

    if (!accessToken.value) {
      return
    }

    const customer: ICustomer = jwtDecode(accessToken.value)

    const path: string = `/v1/customer/users/${customer.id}/promos`

    const { coupons } = useCart()

    coupons.value = await $fetch<IPromo[]>(path, {
      baseURL,
      headers: { AuthToken: accessToken.value },
    })
  }
  catch {
    const { $toast } = useNuxtApp()

    $toast.error('Erro ao buscar cupons de desconto. Tente novamente.')
  }
}
